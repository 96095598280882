var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"megamenu active"},[_c('div',{staticClass:"container p-0"},[_c('div',{staticClass:"mega-content"},[_c('div',{staticClass:"mega-left"},[(!_vm.loading)?_c('ul',{staticClass:"parent-elements"},_vm._l((_vm.categories),function(category){return _c('li',{key:`category-${_vm.categories.indexOf(category)}`,class:{
                            active: _vm.checkHover(_vm.categories.indexOf(category)),
                            'w-1':
                                category.id == 2 ||
                                category.id == 5 ||
                                category.id == 11,
                            'w-3': category.id == 1,
                            'w-4': category.id == 10,
                        },on:{"mousemove":function($event){_vm.mousemove($event, true, _vm.categories.indexOf(category))}}},[_c('div',{attrs:{"to":{ path: '/categorie/' + category.path }}},[_c('img',{attrs:{"width":"20","height":"20","src":category.icon,"loading":"lazy","alt":category.name}}),_c('span',[_vm._v(_vm._s(category.name))])]),_c('div',{staticClass:"category"},[_c('div',{staticClass:"mega-right"},[_c('ul',{staticClass:"mega-subcategory"},_vm._l((category.sub_categories),function(subCategory){return _c('li',{key:`sub-category-${category.sub_categories.indexOf(
                                            subCategory
                                        )}`},[_c('router-link',{attrs:{"to":{ path: '/categorie/' + subCategory.path }}},[_c('span',[_vm._v(_vm._s(subCategory.name))])]),(subCategory.sub_categories.length > 0)?_c('ul',{staticClass:"mega-options"},_vm._l((subCategory.sub_categories),function(grandCategory){return _c('li',{key:`grand-category-${subCategory.sub_categories.indexOf(
                                                    grandCategory
                                                )}`},[_c('router-link',{attrs:{"to":{
                                                        path: '/categorie/' + grandCategory.path,
                                                    }}},[_vm._v(" "+_vm._s(grandCategory.name)+" ")])],1)}),0):_vm._e()],1)}),0)])])])}),0):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }