<template>
    <div class="megamenu">
        <div class="container p-0">
            <div class="mega-left">
                <div
                    class="mega-right"
                    :class="{ active: myNav }"
                >
                    <my-account-navbar
                        location="hamburger-menu"
                        class="my-account-navbar mega-subcategory"
                        @toggleMyNav="toggleMyNav"
                    />
                </div>
                <ul class="parent-elements">
                    <li
                        v-for="category in categories"
                        :key="`category-${categories.indexOf(category)}`"
                        :class="{
                            active:
                                navbar.menu.open &&
                                navbar.menu.index === categories.indexOf(category),
                        }"
                    >
                        <div
                            class="category"
                            @click="markCategory(categories.indexOf(category))"
                        >
                            <img
                                :src="category.icon"
                                :alt="category.name"
                            >
                            <span>{{ category.name }}</span>
                        </div>
                        <chevron-right-icon
                            v-if="category.sub_categories"
                            @click="markCategory(categories.indexOf(category))"
                        />
                        <div class="mega-right">
                            <ul class="mega-subcategory">
                                <li @click="markCategory(categories.indexOf(category))">
                                    <arrow-left-icon />
                                    {{ category.name }}
                                </li>
                                <li
                                    v-for="subCategory in category.sub_categories"
                                    :key="`sub-category-${category.sub_categories.indexOf(
                                        subCategory
                                    )}`"
                                    :class="{
                                        active:
                                            navbar.menu.subMenu.open &&
                                            navbar.menu.subMenu.index ===
                                            category.sub_categories.indexOf(subCategory),
                                    }"
                                >
                                    <span
                                        v-if="subCategory.sub_categories.length > 0"
                                        @click="
                                            markSubCategory(
                                                category.sub_categories.indexOf(subCategory)
                                            )
                                        "
                                    >{{ subCategory.name }}</span>
                                    <router-link
                                        v-else
                                        class="no-child"
                                        :to="{
                                            path: '/categorie/' + subCategory.path,
                                        }"
                                    >
                                        {{ subCategory.name }}
                                    </router-link>
                                    <chevron-down-icon
                                        v-if="subCategory.sub_categories.length > 0"
                                        @click="
                                            markSubCategory(
                                                category.sub_categories.indexOf(subCategory)
                                            )
                                        "
                                    />
                                    <ul class="mega-options">
                                        <li
                                            v-for="grandCategory in subCategory.sub_categories"
                                            :key="`grand-category-${subCategory.sub_categories.indexOf(
                                                grandCategory
                                            )}`"
                                        >
                                            <router-link
                                                :to="{
                                                    path: '/categorie/' + grandCategory.path,
                                                }"
                                            >
                                                {{ grandCategory.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
    import ChevronRightIcon from 'vue-feather-icons/icons/ChevronRightIcon';
    import { mapState } from 'vuex';

    import MyAccountNavbar from './MyAccountNavbar';

    export default {
        name: 'MobileMegaMenu',
        components: {
            ChevronRightIcon,
            ChevronDownIcon,
            ArrowLeftIcon,
            MyAccountNavbar,
        },
        props: {
            myNav: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                navbar: {
                    open: false,
                    menu: {
                        open: false,
                        index: 0,
                        subMenu: {
                            open: false,
                            index: 0,
                            options: {
                                open: false,
                            },
                        },
                    },
                    myNav: false,
                },
                shadow: false,
                active: false,
                scrolled: false,
            };
        },
        computed: {
            ...mapState('navigation', ['categories']),
            ...mapState('general', ['isCustomHAF']),
        },

        methods: {
            markCategory(index) {
                if (!this.navbar.menu.open) {
                    this.navbar.menu.open = true;
                    this.navbar.menu.index = index;
                } else {
                    if (this.navbar.menu.index == index) {
                        this.navbar.menu.open = false;
                        this.navbar.menu.subMenu.open = false;
                    } else {
                        this.navbar.menu.open = true;
                        this.navbar.menu.index = index;
                    }
                }
            },
            markSubCategory(index) {
                if (!this.navbar.menu.subMenu.open) {
                    this.navbar.menu.subMenu.open = true;
                    this.navbar.menu.subMenu.index = index;
                } else {
                    if (this.navbar.menu.subMenu.index == index) {
                        this.navbar.menu.subMenu.open = false;
                    } else {
                        this.navbar.menu.subMenu.open = true;
                        this.navbar.menu.subMenu.index = index;
                    }
                }
            },
            toggleMyNav() {
                this.$emit('toggleMyNav');
            },
        },
    };
</script>
