var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"megamenu"},[_c('div',{staticClass:"container p-0"},[_c('div',{staticClass:"mega-left"},[_c('div',{staticClass:"mega-right",class:{ active: _vm.myNav }},[_c('my-account-navbar',{staticClass:"my-account-navbar mega-subcategory",attrs:{"location":"hamburger-menu"},on:{"toggleMyNav":_vm.toggleMyNav}})],1),_c('ul',{staticClass:"parent-elements"},_vm._l((_vm.categories),function(category){return _c('li',{key:`category-${_vm.categories.indexOf(category)}`,class:{
                        active:
                            _vm.navbar.menu.open &&
                            _vm.navbar.menu.index === _vm.categories.indexOf(category),
                    }},[_c('div',{staticClass:"category",on:{"click":function($event){_vm.markCategory(_vm.categories.indexOf(category))}}},[_c('img',{attrs:{"src":category.icon,"alt":category.name}}),_c('span',[_vm._v(_vm._s(category.name))])]),(category.sub_categories)?_c('chevron-right-icon',{on:{"click":function($event){_vm.markCategory(_vm.categories.indexOf(category))}}}):_vm._e(),_c('div',{staticClass:"mega-right"},[_c('ul',{staticClass:"mega-subcategory"},[_c('li',{on:{"click":function($event){_vm.markCategory(_vm.categories.indexOf(category))}}},[_c('arrow-left-icon'),_vm._v(" "+_vm._s(category.name)+" ")],1),_vm._l((category.sub_categories),function(subCategory){return _c('li',{key:`sub-category-${category.sub_categories.indexOf(
                                    subCategory
                                )}`,class:{
                                    active:
                                        _vm.navbar.menu.subMenu.open &&
                                        _vm.navbar.menu.subMenu.index ===
                                        category.sub_categories.indexOf(subCategory),
                                }},[(subCategory.sub_categories.length > 0)?_c('span',{on:{"click":function($event){_vm.markSubCategory(
                                            category.sub_categories.indexOf(subCategory)
                                        )}}},[_vm._v(_vm._s(subCategory.name))]):_c('router-link',{staticClass:"no-child",attrs:{"to":{
                                        path: '/categorie/' + subCategory.path,
                                    }}},[_vm._v(" "+_vm._s(subCategory.name)+" ")]),(subCategory.sub_categories.length > 0)?_c('chevron-down-icon',{on:{"click":function($event){_vm.markSubCategory(
                                            category.sub_categories.indexOf(subCategory)
                                        )}}}):_vm._e(),_c('ul',{staticClass:"mega-options"},_vm._l((subCategory.sub_categories),function(grandCategory){return _c('li',{key:`grand-category-${subCategory.sub_categories.indexOf(
                                            grandCategory
                                        )}`},[_c('router-link',{attrs:{"to":{
                                                path: '/categorie/' + grandCategory.path,
                                            }}},[_vm._v(" "+_vm._s(grandCategory.name)+" ")])],1)}),0)],1)})],2)])],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }