<template>
    <div
        class="megamenu active"
    >
        <div class="container p-0">
            <div class="mega-content">
                <div class="mega-left">
                    <ul
                        v-if="!loading"
                        class="parent-elements"
                    >
                        <li
                            v-for="category in categories"
                            :key="`category-${categories.indexOf(category)}`"
                            :class="{
                                active: checkHover(categories.indexOf(category)),
                                'w-1':
                                    category.id == 2 ||
                                    category.id == 5 ||
                                    category.id == 11,
                                'w-3': category.id == 1,
                                'w-4': category.id == 10,
                            }"
                            @mousemove="
                                mousemove($event, true, categories.indexOf(category))
                            "
                        >
                            <div :to="{ path: '/categorie/' + category.path }">
                                <img
                                    width="20"
                                    height="20"
                                    :src="category.icon"
                                    loading="lazy"
                                    :alt="category.name"
                                >
                                <span>{{ category.name }}</span>
                            </div>
                            <div class="category">
                                <div class="mega-right">
                                    <ul class="mega-subcategory">
                                        <li
                                            v-for="subCategory in category.sub_categories"
                                            :key="`sub-category-${category.sub_categories.indexOf(
                                                subCategory
                                            )}`"
                                        >
                                            <router-link
                                                :to="{ path: '/categorie/' + subCategory.path }"
                                            >
                                                <span>{{ subCategory.name }}</span>
                                            </router-link>
                                            <ul
                                                v-if="subCategory.sub_categories.length > 0"
                                                class="mega-options"
                                            >
                                                <li
                                                    v-for="grandCategory in subCategory.sub_categories"
                                                    :key="`grand-category-${subCategory.sub_categories.indexOf(
                                                        grandCategory
                                                    )}`"
                                                >
                                                    <router-link
                                                        :to="{
                                                            path: '/categorie/' + grandCategory.path,
                                                        }"
                                                    >
                                                        {{ grandCategory.name }}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';


    export default {
        name: 'DesktopMegaMenu',

        data() {
            return {
                megaMenuIsActive: false,
                megaMenuToDeactivate: false,
                hover: {
                    active: false,
                    index: 1,
                },
                cursor: {
                    x: 0,
                    y: 0,
                },
                timeout: 0,
                otherHover: false,
                sublineElement: {
                    opened: false,
                    width: '0px',
                    left: '0px',
                },
            };
        },
        computed: {
            ...mapState('navigation', ['categories', 'loading']),
            ...mapState('general', ['isCustomHAF']),
        },

        methods: {
            mousemove(event, active, index) {
                this.cursor.x = event.clientX;
                this.cursor.y = event.clientY;
                this.mouseIsStillMoving(active, index);
            },
            mouseIsStillMoving(active, index) {
                let cursorX = this.cursor.x;
                let cursorY = this.cursor.y;

                setTimeout(() => {
                    setTimeout(() => {
                        let diffX = this.cursor.x - cursorX;
                        if (diffX > 1) {
                            this.timeout = 20;
                        } else {
                            this.timeout = 0;
                        }

                        if (cursorX == this.cursor.x && cursorY == this.cursor.y) {
                            this.hover.active = active;
                            this.hover.index = index;
                        }
                    }, this.timeout);
                }, 13);
            },

            checkHover(index) {
                return index === this.hover.index;
            },
            activateMegaMenu() {
                this.megaMenuIsActive = true;
                this.megaMenuToDeactivate = false;
                this.subline(null, 'full');
            },
            deactivateMegaMenu() {
                this.megaMenuToDeactivate = true;
                setTimeout(() => {
                    if (this.megaMenuToDeactivate) {
                        this.megaMenuIsActive = false;
                    }
                }, 200);
            },
            subline(event, size) {
                if (size == 'full') {
                    this.sublineElement.left = '0px';
                    this.sublineElement.width = '100%';
                } else {
                    this.sublineElement.left = event.target.offsetLeft + 'px';
                    this.sublineElement.width = event.target.offsetWidth + 'px';
                }

                if (!this.sublineElement.opened) {
                    setTimeout(() => {
                        this.sublineElement.opened = true;
                    }, 20);
                }
            },
            resetSubline() {
                this.sublineElement.width = '0px';
                this.sublineElement.left = '0px';
                this.sublineElement.opened = false;
            },
        },
        watch: {
            '$route.params'() {
                this.megaMenuIsActive = false;
            },
        },
    };
</script>
